import React from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

const ContactPage: React.FC = () => {
    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs lg="6">
                    <h1>Contato (não ta funcionando)</h1>
                    <Form
                        method="POST"
                    >
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your name" />
                        </Form.Group>

                        <Form.Group controlId="formBasicMessage">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea"/>
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default ContactPage;