import React, { useState } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';

const CharacterCounter: React.FC = () => {
    const [inputText, setInputText] = useState<string>('');
    const [charCount, setCharCount] = useState<number>(0);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value;
        setInputText(text);
        setCharCount(text.length);
    };

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs lg="6">
                    <h1>Character Counter</h1>
                    <Form>
                        <Form.Group controlId="formBasicInput">
                            <Form.Label>Input Text</Form.Label>
                            <Form.Control
                                type="text"
                                value={inputText}
                                onChange={handleInputChange}
                                placeholder="Enter text"
                                style={{ height: '100px' }}
                            />
                        </Form.Group>
                        <p>Character Count: {charCount}</p>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default CharacterCounter;