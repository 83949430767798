import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const PlaybackTimeAdjuster = () => {
    const [hours, setHours] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);
    const [seconds, setSeconds] = useState<number>(0);
    const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
    const [adjustedTime, setAdjustedTime] = useState<string>('');

    useEffect(() => {
        const totalSeconds = hours * 3600 + minutes * 60 + seconds;
        const adjustedSeconds = totalSeconds / playbackSpeed;

        const adjustedHours = Math.floor(adjustedSeconds / 3600);
        const adjustedMinutes = Math.floor((adjustedSeconds % 3600) / 60);
        const adjustedSecondsRemaining = Math.floor(adjustedSeconds % 60);

        setAdjustedTime(`${adjustedHours}h ${adjustedMinutes}m ${adjustedSecondsRemaining}s`);
    }, [hours, minutes, seconds, playbackSpeed]);

    return (
        <Form>
            <Row/>
            <Row>
                <Col>
                    <Form.Group controlId="formHours">
                        <Form.Label style={{ fontSize: '1.5rem' }}>Hours</Form.Label>
                        <Form.Control
                            type="number"
                            value={hours}
                            onChange={(e) => setHours(Number(e.target.value))}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formMinutes">
                        <Form.Label style={{ fontSize: '1.5rem' }}>Minutes</Form.Label>
                        <Form.Control
                            type="number"
                            value={minutes}
                            onChange={(e) => setMinutes(Number(e.target.value))}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formSeconds">
                        <Form.Label style={{ fontSize: '1.5rem' }}>Seconds</Form.Label>
                        <Form.Control
                            type="number"
                            value={seconds}
                            onChange={(e) => setSeconds(Number(e.target.value))}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formPlaybackSpeed">
                        <Form.Label style={{ fontSize: '1.5rem' }}>Playback Speed</Form.Label>
                        <Form.Control
                            type="number"
                            step="0.1"
                            value={playbackSpeed}
                            onChange={(e) => setPlaybackSpeed(Number(e.target.value))}
                        />
                    </Form.Group>
                </Col>
            </Row>
            {adjustedTime && (
                <div>
                    <h2>Adjusted Time:</h2>
                    <p>{adjustedTime}</p>
                </div>
            )}
        </Form>
    );
};

export default PlaybackTimeAdjuster;