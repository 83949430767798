import React, {useState, useRef} from 'react';
import {Form, Button} from 'react-bootstrap';

const Music = () => {
    const [value, setValue] = useState(" ");
    const textField = useRef(null);

    const buttonClickHandler = () => {
        // @ts-ignore
        setValue(textField.current.value);
    };

    return (
        <div>
            <Form>
                <Form.Group className="mb-3" controlId="formTextInput">
                    <Form.Label>Enter text to update value:</Form.Label>
                    <Form.Control type="text" ref={textField} defaultValue={value}/>
                </Form.Group>
                <Button variant="primary" onClick={buttonClickHandler}>
                    Update Value
                </Button>
            </Form>
            <p>{value}</p>
        </div>
    );
};

export default Music;