import logo from "assets/img/logo.png"
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import {Nav} from "react-bootstrap";
import {Link} from "react-router-dom";

function NavBar() {
    return (
        <div>
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                <Container>
                    <Navbar.Brand href="/">
                        <img src={logo} alt={"deu ruim"} width="100"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Link className="nav-link align-content-md-center" to="/tools"> Tools </Link>
                            <Link className="nav-link align-content-md-center" to="/music"> Music </Link>
                            <Link className="nav-link align-content-md-center" to="/test"> Test </Link>
                            <Link className="nav-link align-content-md-center" to="/contato"> Contato </Link>
                            <Link className="nav-link align-content-md-center" to="/teste"> Teste </Link>

                        </Nav>
                        <Nav>
                            <Nav.Link href="#alguma">Alguma coisa</Nav.Link>
                            <Nav.Link eventKey={2} href="#coisa">
                                Outra coisa
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </div>)
}

export default NavBar;