import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { diffChars } from 'diff';

const TextCompare = () => {
    const [text1, setText1] = useState<string>('');
    const [text2, setText2] = useState<string>('');
    const [highlightedText, setHighlightedText] = useState<string>('');

    const handleCompare = () => {
        const diff = diffChars(text1, text2);
        const highlighted = diff.map((part, index) => {
            const color = part.added ? 'green' : part.removed ? 'red' : 'black';
            return (
                <span key={index} style={{ backgroundColor: part.added ? 'lightgreen' : part.removed ? 'lightcoral' : 'transparent' }}>
                    {part.value}
                </span>
            );
        });
        setHighlightedText(highlighted as unknown as string);
    };

    return (
        <Container>
            <h1>Text Compare</h1>
            <Form>
                <Row>
                    <Col>
                        <Form.Group controlId="formText1">
                            <Form.Label>Text 1</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={10}
                                value={text1}
                                onChange={(e) => setText1(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formText2">
                            <Form.Label>Text 2</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={10}
                                value={text2}
                                onChange={(e) => setText2(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <br/>

                <Button variant="primary" onClick={handleCompare}>
                    Compare
                </Button>
            </Form>
            {highlightedText && (
                <div>
                    <h2>Highlighted Differences:</h2>
                    <div>{highlightedText}</div>
                </div>
            )}
        </Container>
    );
};

export default TextCompare;