import { Container, Tab, Tabs } from 'react-bootstrap';
import PlaybackTimeAdjuster from "../../components/PlaybackTimeAdjuster";
import TextCompare from "../../components/TextCompare";
import CharacterCounter from "../../components/CharacterCounter";

const Tools = () => {
    return (
        <Container>
            <h1>Tools</h1>
            <Tabs defaultActiveKey="playbackTimeAdjuster" id="tools-tabs">
                <Tab eventKey="playbackTimeAdjuster" title="Playback Time Adjuster">
                    <PlaybackTimeAdjuster />
                </Tab>
                <Tab eventKey="textCompare" title="Text Compare">
                    <TextCompare />
                </Tab>
                <Tab eventKey="characterCounter" title="Character Counter">
                    <CharacterCounter/>
                </Tab>
            </Tabs>
        </Container>
    );
};

export default Tools;