import React from 'react';
import ChromeDinoGame from 'react-chrome-dino';
import logo from "assets/img/yoshi.gif"
import Image from "react-bootstrap/Image";



const Home = () => {
    return (
        <>
            <div className="container">
                <div className="jumbotron">
                    <h1 className="display-4">Alguma coisa</h1>
                    <p className="lead">Outra coisa</p>
                    <hr/>
                    <Image src = {logo} className="yoshi-photo" alt={"loading"} />
                    <br/>
                    <br/>

                    <ChromeDinoGame />

                </div>
            </div>
        </>
    )
};

export default Home;