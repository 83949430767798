import React from 'react';
import ReactPlayer from 'react-player';
import {Col, Row} from "react-bootstrap";


const Music = () => {

    return (
        <div>
            <Row md={12}>
                <Col md={4} >
                    <ReactPlayer url={"https://soundcloud.com/hidanko/yesterday"} controls/>
                </Col>
                <Col md={4}>
                    <ReactPlayer url={"https://soundcloud.com/hidanko/ondas"} controls/>
                </Col>
                <Col md={4}>
                    <ReactPlayer url={"https://soundcloud.com/hidanko/cloudy-nights"} controls/>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <ReactPlayer url={"https://soundcloud.com/hidanko/luzes"} controls/>
                </Col>
                <Col md={4}>
                    <ReactPlayer url={"https://soundcloud.com/hidanko/twilight"} controls/>
                </Col>
                <Col md={4}>
                    <ReactPlayer url={"https://soundcloud.com/hidanko/giz"} controls/>
                </Col>
            </Row>
        </div>
    );
};


export default Music;