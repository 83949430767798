import React from 'react';
import AppRoutes from './Routes';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
    return (

        <AppRoutes/>
    );
};

export default App;