function Footer() {
    return (

            <footer className="footer mt-auto py-3 bg-dark">
                <div className="container">
                    <p> All rights are reserved</p>
                </div>
            </footer>
        )
}

export default Footer;