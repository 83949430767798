import React, { useState } from "react";
import { Container, Form, Button, Row, Col, Dropdown } from "react-bootstrap";
import { downloadFormsData } from "../../utils/downloadForms";

export interface FormState {
    name: string;
    value: number | '';
    total: number;
    changes: string[];
}

const Caixa: React.FC = () => {
    const initialFormState: FormState = { name: '', value: '', total: 0, changes: [] };
    const [forms, setForms] = useState<FormState[]>(Array(10).fill(initialFormState));
    const [activeFormIndex, setActiveFormIndex] = useState<number>(0);

    const handleAdd = () => {
        const currentForm = forms[activeFormIndex];
        if (currentForm.value !== '') {
            const updatedForm: FormState = {
                ...currentForm,
                total: currentForm.total + Number(currentForm.value),
                changes: [...currentForm.changes, `Added: ${currentForm.value}`],
                value: '' as const
            };
            updateFormState(updatedForm);
        }
    };

    const handleSubtract = () => {
        const currentForm = forms[activeFormIndex];
        if (currentForm.value !== '') {
            const updatedForm: FormState = {
                ...currentForm,
                total: currentForm.total - Number(currentForm.value),
                changes: [...currentForm.changes, `Subtracted: ${currentForm.value}`],
                value: '' as const
            };
            updateFormState(updatedForm);
        }
    };

    const updateFormState = (updatedForm: FormState) => {
        setForms(forms.map((form, index) => index === activeFormIndex ? updatedForm : form));
    };

    return (
        <Container>
            <h1>Caixa</h1>
            <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    Select Form {activeFormIndex + 1}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {forms.map((form, index) => (
                        <Dropdown.Item key={index} onClick={() => setActiveFormIndex(index)}>
                            {form.name || `Form ${index + 1}`}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            <Form>
                <Form.Group controlId="formName">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                        type="text"
                        value={forms[activeFormIndex].name}
                        onChange={(e) => updateFormState({ ...forms[activeFormIndex], name: e.target.value })}
                        placeholder="Enter name"
                    />
                </Form.Group>
                <Form.Group controlId="formValue">
                    <Form.Label>Valor</Form.Label>
                    <Form.Control
                        type="number"
                        value={forms[activeFormIndex].value}
                        onChange={(e) => updateFormState({ ...forms[activeFormIndex], value: e.target.value ? Number(e.target.value) : '' })}
                        placeholder="Enter value"
                    />
                </Form.Group>
                <Form.Group controlId="formTotal">
                    <Form.Label>Total</Form.Label>
                    <Form.Control
                        type="text"
                        value={forms[activeFormIndex].total}
                        readOnly
                    />
                </Form.Group>
                <Row>
                    <Col>
                        <Button variant="success" onClick={handleAdd}>
                            +
                        </Button>
                    </Col>
                    <Col>
                        <Button variant="danger" onClick={handleSubtract}>
                            -
                        </Button>
                    </Col>
                </Row>
                <Form.Group controlId="formChanges">
                    <Form.Label>Log</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        value={forms[activeFormIndex].changes.join('\n')}
                        readOnly
                    />
                </Form.Group>
                <br/>

                <Button variant="primary" onClick={() => downloadFormsData(forms)}>
                    Download
                </Button>
            </Form>
        </Container>
    );
}

export default Caixa;