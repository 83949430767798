import { FormState } from '../pages/Caixa';  // Adjust the path as necessary

export const downloadFormsData = (forms: FormState[]) => {
    let content = "Summary of Forms:\n\n";

    forms.forEach((form, index) => {
        content += `Form ${index + 1} - Name: ${form.name || 'Unnamed'}, Total: ${form.total}\n`;
    });

    content += "\nForms with Changes:\n\n";

    forms.forEach((form, index) => {
        if (form.changes.length > 0) {
            content += `Form ${index + 1} - Name: ${form.name || 'Unnamed'}\n`;
            content += `Changes:\n${form.changes.join('\n')}\n\n`;
        }
    });

    const blob = new Blob([content], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'forms_summary.txt';
    a.click();
    URL.revokeObjectURL(url);
};